import React from "react";
import {Menu} from 'antd';
import {Link} from "react-router-dom";
import data from "./data";

interface Props {
    mode : "vertical" | "horizontal" | "inline"
}

const Menus : React.FC<Props> = ({mode}) => {
    return (
        <Menu defaultSelectedKeys={['1']} mode={mode}>
            <Menu.Item key="1">
                <Link to={"/#"}>{data.id.home}</Link>
            </Menu.Item>
            <Menu.Item key="2" >
                <a href={"/#about"}>{data.id.about}</a>
            </Menu.Item>
            <Menu.Item key="6" >
                <a href={"/#vision"}>Visi</a>
            </Menu.Item>
            <Menu.Item key="7" >
                <a href={"/#mision"}>Misi</a>
            </Menu.Item>
            <Menu.Item key="8" >
                <a href={"/#vision"}>Testimoni</a>
            </Menu.Item>
            <Menu.Item key="9" >
                <a href={"/#mision"}>Kontak</a>
            </Menu.Item>
            <Menu.Item key="4" className={"mobile-visible"}>
                <a href={"https://dashboard.etc-cat.com/masuk"}>{data.id.login}</a>
            </Menu.Item>
            <Menu.Item key="5" className={"mobile-visible"} >
                <a href={"https://dashboard.etc-cat.com/daftar"}>{data.id.register}</a>
            </Menu.Item>
        </Menu>
    )
}

export default React.memo(Menus);