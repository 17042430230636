import React, {useEffect} from "react";
import { Layout } from 'antd';
import {DrawerProvider} from "contexts/drawer";
import ReactGA from 'react-ga';
import Header from "../header";
import Drawer from "../drawer";
import Footer from "../footer";

const BaseLayout : React.FC = (props) => {

    // log current page
    useEffect(() => {

        ReactGA.set({page : window.location.pathname})
        ReactGA.pageview(window.location.pathname);

        let observer = new PerformanceObserver(list => {
            list.getEntries().forEach(entry => {
                ReactGA.timing({
                    category: "Download Time",
                    variable: 'Server Latency',
                    value: entry.duration
                })
            })
        })

        observer.observe({entryTypes: ['navigation'] })

    }, [])

    return (
        <Layout className={"public-layout"}>
            <DrawerProvider>
                <Header />
                <Drawer />
            </DrawerProvider>
            <Layout.Content>
                {props.children}
            </Layout.Content>
            <Footer />
        </Layout>
    )
}

export default BaseLayout;