import React from "react";
import {Space, Spin} from "antd";
import { SyncOutlined } from '@ant-design/icons';
import "assets/styles/loader.less";

interface Props {
    tip: string,
    spinning: boolean
}

const Spinner: React.FC<Props> = ({spinning, }) => {
    return (
        <div className={"spinner-container"}>
            <Spin
                indicator={
                    <div className={"spinner-container"}>
                        <Space direction={"vertical"}>
                            <div className={"banner"}>
                                <img width={200} src={"/images/loading-banner.svg"} alt={""} />
                            </div>
                            <div className={"icon"}>
                                <SyncOutlined style={{ fontSize: 24 }} spin />
                            </div>
                        </Space>
                    </div>
                }
                className={"spin"}
                size={"large"}
                spinning={spinning}
                tip={""}
            />
        </div>
    )
};

export default Spinner;