const data = {
    id : {
        home     : "Rumah",
        about    : "Tentang",
        service  : "Layanan",
        login    : "Masuk",
        register : "Daftar"
    },
    en : {
        home     : "Home",
        about    : "About",
        service  : "Service",
        login    : "Login",
        register : "Register"
    }
}

export default data