import React, {useContext, useEffect} from "react";
import {Button, Layout, Space} from "antd";
import {FacebookOutlined, InstagramOutlined, MenuOutlined, TwitterOutlined} from "@ant-design/icons"
import Menu from "../menu";
import {DrawerContext} from "contexts/drawer";

const Header: React.FC = () => {

    const [, setVisible] = useContext(DrawerContext);

    useEffect(() => {

        let navbar = document.getElementById("navbar");

        window.onscroll = () => {
            if (window.pageYOffset > 100) {
                navbar?.classList.add("scrolled")
            } else {
                navbar?.classList.remove("scrolled")
            }
        }
    }, [])

    return (
        <Layout.Header id={"navbar"} className="public-header">
           <div className={"public-container"}>
               <div className={"logo"}>
                   <img width={60} src={"/logo.jpg"} alt={"Logo"}/>
               </div>

               <div className={"social-media"}>
                   <Space>
                       <Button
                           type={"primary"}
                           ghost={false}
                           href={"https://dashboard.etc-cat.com/daftar"}
                       >
                           Daftar
                       </Button>
                      <Button
                          type={"primary"}
                          ghost={true}
                          href={"https://dashboard.etc-cat.com/masuk"}
                      >
                          Masuk
                      </Button>
                   </Space>
               </div>

               <div className={"button-menu"}>
                   <Button shape={"circle"} type={"primary"} onClick={() => setVisible(true)}>
                       <MenuOutlined />
                   </Button>
               </div>

               <div className={"navigation"}>
                   <Menu mode={"horizontal"} />
               </div>

           </div>
        </Layout.Header>
    )
}

export default React.memo(Header);